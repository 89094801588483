import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import SEO from '../components/seo';

const PageWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }

  .page-header {
    margin-bottom: 2rem;
    flex: 1;
  }
  .main-content {
    flex: 4;
    margin-bottom: 4rem;
  }
`;

export default function Post({ data }) {
  console.log(data);
  const { title } = data.post;
  return (
    <PageWrapper>
      <SEO title={title} />
      <header className="page-header">
        <h1 className="page-title">{title}</h1>
      </header>
      <section className="main-content">
        <BlockContent blocks={data.post._rawMainContent} />
      </section>
    </PageWrapper>
  );
}

export const query = graphql`
  query($slug: String!) {
    post: sanityPost(slug: { current: { eq: $slug } }) {
      title
      id
      _rawMainContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;
